import React from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PlayerStats from '../components/PlayerStats'
import HeadTags from '../components/Headtags4'

import SnackAd from '../components/SnackAd'
import SnackAd2 from '../components/SnackAd2'
import {Container,Row,Col} from "react-bootstrap"

const PlayerStatsPage = () => (
  <>
    <HeadTags />
    <div style ={{position:"relative", minHeight:"100vh"}}>
    <Row>
      <Col xs={12} lg={2} className="d-none d-lg-block">
      </Col>
      <Col xs={12} lg={8}>
        <SnackAd2 ad_type = "leader" ad_num = {1}/>
      </Col>
      <Col xs={12} lg={2} className="d-none d-lg-block">
      </Col>
    </Row>
      <Header />
      <PlayerStats />
      <Container fluid>
        <Row>
          <Col xs={12} lg={2} className="d-none d-lg-block">
          </Col>
          <Col xs={12} lg={8}>
            <SnackAd ad_type = "leader" ad_num = {4}/>
          </Col>
          <Col xs={12} lg={2} className="d-none d-lg-block">
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  </>
)

export default PlayerStatsPage
