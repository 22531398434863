import React, { useState, useEffect} from "react";
import styles from './PlayerStats.module.scss';
// import { API, Storage } from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
// import { listNotes } from './graphql/queries';
// import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from './graphql/mutations';
import XgPop from '../components/XgPop'
import {Table,Container,Row,Col} from "react-bootstrap"
import Form from 'react-bootstrap/Form'
// import PlayerPop from '../components/PlayerPop'
// import GoogleAd from '../components/GoogleAd'
import SnackAd from '../components/SnackAd'


const axios = require("axios");

function GwInfo(props) {

  const gameWeek = props.gw>0 ? ("Gameweek " + props.gw) : null

  return(
    <Container fluid className ={styles.gwInfo}>
      <Row>
        <Col>
          <h1 className ={styles.gameweekh1}>
            Player Stats
          </h1>
        </Col>
      </Row>
    </Container>
  )
}

function arrw(x,y,z) {
  if (x!=y) {
    return null;
  }
else if(x==y && z==1){
  return (
     <> &#9650; </>
  );
}
else{
  return (
     <> &#9660; </>
  );
}
}

function PlayerStatsXG(props) {

  if(props.fplStats.length==0){
    return null
  }
  else{

    const style_0 = props.rankOrd===0 ? "bg-secondary" : "bg-default";
    const style_1 = props.rankOrd===1 ? "bg-secondary" : "bg-default";
    const style_2 = props.rankOrd===2 ? "bg-secondary" : "bg-default";
    const style_3 = props.rankOrd===3 ? "bg-secondary" : "bg-default";
    const style_4 = props.rankOrd===4 ? "bg-secondary" : "bg-default";
    const style_5 = props.rankOrd===5 ? "bg-secondary" : "bg-default";
    const style_6 = props.rankOrd===6 ? "bg-secondary" : "bg-default";
    const style_7 = props.rankOrd===7 ? "bg-secondary" : "bg-default";
    const style_8 = props.rankOrd===8 ? "bg-secondary" : "bg-default";
    const style_9 = props.rankOrd===9 ? "bg-secondary" : "bg-default";
    const style_10 = props.rankOrd===10 ? "bg-secondary" : "bg-default";
    const style_11 = props.rankOrd===11 ? "bg-secondary" : "bg-default";
    const style_12 = props.rankOrd===12 ? "bg-secondary" : "bg-default";
    const style_13 = props.rankOrd===13 ? "bg-secondary" : "bg-default";
    const style_14 = props.rankOrd===14 ? "bg-secondary" : "bg-default";
    const style_15 = props.rankOrd===15 ? "bg-secondary" : "bg-default";
    const style_16 = props.rankOrd===16 ? "bg-secondary" : "bg-default";
    const style_17 = props.rankOrd===17 ? "bg-secondary" : "bg-default";
    const style_18 = props.rankOrd===18 ? "bg-secondary" : "bg-default";

    const ord_switch = props.ordType===1 ? 0 : 1;

    const xg_header = props.selections.period ===0 ? "All Season" : props.selections.period ===1 ? "Last GW" : " Last 3 GWs";

  return (
    <Table
      responsive
      key={props.fid + props.type}
      size="sm"
      className={styles.players_table + " shadow"}
    >
      <thead className="thead-dark">
        <tr>
          <th rowSpan="2" className ={"d-none d-sm-table-cell"} style={{verticalAlign:"middle"}}>#</th>
          <th rowSpan="2" className ={"border-right"} style={{textAlign:"left",verticalAlign:"middle"}}>Player</th>
          <th rowSpan="2" className ={"d-none d-sm-table-cell border-right"} style={{verticalAlign:"middle"}}>Pos</th>
          <th colSpan="4" className={"border-right"}>Info</th>
          <th colSpan="3" className={"border-right"}>Player</th>
          <th colSpan="3">Team</th>
        </tr>
        <tr>
          <th className={style_11} onClick={()=>props.setUrl(props.newUrl(props.selections,11,0))} style={{cursor:"pointer"}}>C£ {arrw(11,props.rankOrd,props.ordType)}</th>
          <th className={style_12} onClick={()=>props.setUrl(props.newUrl(props.selections,12,0))} style={{cursor:"pointer"}}>S% {arrw(12,props.rankOrd,props.ordType)}</th>
          <th className={style_1} onClick={()=>props.setUrl(props.newUrl(props.selections,1,0))} style={{cursor:"pointer"}}>Fm {arrw(1,props.rankOrd,props.ordType)}</th>
          <th className={style_0 + " border-right"} onClick={()=>props.setUrl(props.newUrl(props.selections,0,0))} style={{cursor:"pointer"}}>Ps {arrw(0,props.rankOrd,props.ordType)}</th>
          <th className={style_10} onClick={()=>props.setUrl(props.newUrl(props.selections,10,0))} style={{cursor:"pointer"}}>Pred Pts{arrw(10,props.rankOrd,props.ordType)}</th>
          <th className={style_6} onClick={()=>props.setUrl(props.newUrl(props.selections,6,0))} style={{cursor:"pointer"}}>Sc%{arrw(6,props.rankOrd,props.ordType)}</th>
          <th className={style_5+ " border-right"} onClick={()=>props.setUrl(props.newUrl(props.selections,5,0))} style={{cursor:"pointer"}}>As%{arrw(5,props.rankOrd,props.ordType)}</th>
          <th className={style_2} onClick={()=>props.setUrl(props.newUrl(props.selections,2,0))} style={{cursor:"pointer"}}>W% {arrw(2,props.rankOrd,props.ordType)}</th>
          <th className={style_3} onClick={()=>props.setUrl(props.newUrl(props.selections,3,0))} style={{cursor:"pointer"}}>CS% {arrw(3,props.rankOrd,props.ordType)}</th>
          <th className={style_4} onClick={()=>props.setUrl(props.newUrl(props.selections,4,0))} style={{cursor:"pointer"}}>3+gls% {arrw(4,props.rankOrd,props.ordType)}</th>

        </tr>
      </thead>
      <tbody>
        {props.fplStats.map(plyr => (
          <React.Fragment key={plyr.rank}>
          <tr style={{padding:"0"}}>
           <td className ={"d-none d-sm-table-cell"}>{plyr.rank}</td>
            <td className ={"border-right"}
              style={{textAlign:"left",paddingBottom:"0"}}
            >
              {displayName2(plyr.pl_name,[plyr.chance_nr,plyr.start_lu])}
            </td>
            <td className ={"d-none d-sm-table-cell border-right"}>
              {plyr.pos}
            </td>
            <td>
              {plyr.cost}
            </td>
            <td>
              {plyr.selected}
            </td>
            <td>
              {plyr.form}
            </td>
            <td className={"border-right"}>
              {plyr.total_points}
            </td>
            <td>
              {plyr.fpl_pts_pred}
            </td>
            <td>
              {plyr.score==0 ? '-' :plyr.score+'%'}
            </td>
            <td>
              {plyr.assist==0 ? '-':plyr.assist+'%'}
            </td>
            <td>
              {plyr.win}%
            </td>
            <td>
              {plyr.clean}%
            </td>
            <td >
              {plyr.over2_5}%
            </td>
          </tr>
          <tr style={{padding:"0",lineHeight: "10px"}}>
            <td className ={"d-none d-sm-table-cell border-top-0"}></td>
             <td
               style={{textAlign:"left",paddingTop:"0"}} className="border-top-0 border-right"
             >
               <span className="font-italic text-secondary">
               {plyr.team}
               </span>
             </td>
             <td className ="d-none d-sm-table-cell border-right border-top-0"></td>
             <td className="border-top-0"></td>
             <td className="border-top-0"></td>
             <td className="border-top-0"></td>
             <td className="border-right border-top-0"></td>

             <td className="border-top-0" colSpan="6" style={{paddingTop:"0"}} >
                <span className='font-italic text-secondary'>vs {plyr.opp}</span>
             </td>
          </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}
}

function displayName2(name, actions) {
  var name2=name;
      if(actions[0]==0){
      name2+=" \u274c"
        }
        else if(actions[0]<75){
        name2+="\u2757"
        }
        else if(actions[0]<100){
        name2+="\u2757"
        }
        if(actions[1]==0 && actions[0]>0){
        name2+="\u2753"
          }


return name2;
}



class SelForm extends React.Component {
// function PhaseForm() {
constructor(props) {
    super(props);
    this.state = {value: '2'};
    // this.setUrl = this.props.setUrl;
    // this.newUrl = this.props.newUrl;
    this.handleChangePos = this.handleChangePos.bind(this);
    this.handleChangeDiff = this.handleChangeDiff.bind(this);
    this.handleChangePrice= this.handleChangePrice.bind(this);
    this.handleChangeTeam= this.handleChangeTeam.bind(this);
    this.handleChangePeriod= this.handleChangePeriod.bind(this);
}

  handleChangePos(event) {
    this.props.selections.pos = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  handleChangeDiff(event) {
    this.props.selections.diff = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  handleChangePrice(event) {
    this.props.selections.price = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  handleChangeTeam(event) {
    this.props.selections.tcode = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  handleChangePeriod(event) {
    this.props.selections.period = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  render() {
    return  (
      <>
      <Row style={{textAlign:"centre",marginLeft: -5,marginRight: -5  }}>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Control
            as="select" value={this.props.selections.pos}
            onChange={this.handleChangePos}
            size="sm"
            >
            <option value='0'>All</option>
            <option value='1'>GK</option>
            <option value='2'>Def</option>
            <option value='3'>Mid</option>
            <option value='4'>Fwd</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Control
            as="select" value={this.props.selections.diff}
            onChange={this.handleChangeDiff}
             size="sm"
            >
            <option value='0'>All</option>
            <option value='1'>30%</option>
            <option value='2'>20%</option>
            <option value='3'>10%</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect3">
          <Form.Control
            as="select" value={this.props.selections.price}
            onChange={this.handleChangePrice}
            size="sm"
            >
            <option value='0'>All</option>
            <option value='1'>£12M</option>
            <option value='2'>£11M</option>
            <option value='3'>£10M</option>
            <option value='4'>£9M</option>
            <option value='5'>£8M</option>
            <option value='6'>£7M</option>
            <option value='7'>£6M</option>
            <option value='8'>£5M</option>
            <option value='9'>£4M</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect4">
          <Form.Control
            as="select" value={this.props.selections.tcode}
            onChange={this.handleChangeTeam}
            size="sm"
            >
            <option value='0'>All</option>
            <option value='1'>Arsenal</option>
            <option value='2'>Aston Villa</option>
            <option value='3'>Bournemouth</option>
            <option value='4'>Brentford</option>
            <option value='5'>Brighton</option>
            <option value='6'>Chelsea</option>
            <option value='7'>Crystal Palace</option>
            <option value='8'>Everton</option>
            <option value='9'>Fulham</option>
            <option value='10'>Leeds</option>
            <option value='11'>Leicester</option>
            <option value='12'>Liverpool</option>
            <option value='13'>Man City</option>
            <option value='14'>Man Utd</option>
            <option value='15'>Newcastle</option>
            <option value='16'>Nottingham Forest</option>
            <option value='17'>Southampton</option>
            <option value='18'>Spurs</option>
            <option value='19'>West Ham</option>
            <option value='20'>Wolves</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      </Row>

      </>
    );
}
}



// <Row>
// <Col>
// <Form className={styles.inputForm}>
//   <Form.Group controlId="exampleForm.ControlSelect5">
//     <Form.Control
//       as="select" value={this.props.selections.period}
//       onChange={this.handleChangePeriod}
//       size="sm"
//       >
//       <option value='0'>All</option>
//       <option value='1'>Last GW</option>
//       <option value='2'>Last 3 GWs</option>
//     </Form.Control>
//   </Form.Group>
// </Form>
// </Col>
// </Row>

// var pos_get = 0;


// const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_live_19';


const useMatchesState = () => {
  const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_stats';
  // const [gw, setGW] = useState(0);
  const [url, setUrl] = useState(live_url)
  const [responseData, setResponseData] = useState([]);

  //PLAYER POP UP STATE
  const [show, setShow] = useState(false);
  const [plData, setplData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = async (newPlCode) => {

    const plCode_url = "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_stats_full?pl_code="+newPlCode
    console.log('fetch_url:'+plCode_url)
    const response = await axios.get(plCode_url);
    setplData(response.data);
    console.log("fetchran_plcode");
    setShow(true);
    };

    var xgInfo = [];

    if (Object.keys(plData).length > 0) {
      xgInfo = plData.info;
    }
//END

  const newUrl = (selections_x,ord,new_x) => {
    const pos_x = selections_x.pos;
    const diff_x = selections_x.diff;
    const price_x = selections_x.price;
    const team_x = selections_x.tcode;
    const period_x = selections_x.period;

    if(new_x==1){
      return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_stats?pos='+pos_x+'&diff='+diff_x+'&price='+price_x+'&ord='+ord+'&ord_type='+ordType+'&tcode='+team_x+'&period='+period_x;
    }
    else if(ord === rankOrd && ordType==0 && new_x==0){
      return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_stats?pos='+pos_x+'&diff='+diff_x+'&price='+price_x+'&ord='+ord+'&ord_type=1'+'&tcode='+team_x+'&period='+period_x;
    }
      else {
        return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_stats?pos='+pos_x+'&diff='+diff_x+'&price='+price_x+'&ord='+ord+'&ord_type=0'+'&tcode='+team_x+'&period='+period_x;
      }
    }

  var fplstats = [];
  var gw = 0;
  var rankOrd =0;
  var ordType = 0;
  var selections =[];
  var stats_updated = "";

  if (Object.keys(responseData).length > 0) {

    fplstats = responseData.stats;
    gw = responseData.summary.gw;
    rankOrd = responseData.summary.ord;
    ordType = responseData.summary.ord_type;
    selections = responseData.summary.selections;
    stats_updated = responseData.summary.stats_updated;

  }

  const fetchFpl = async (url) => {

    console.log('fetch_url:'+url)

    const response = await axios.get(url);
    setResponseData(response.data);
    console.log("fetchran");

  };

  useEffect(() => {
    fetchFpl(url);
    // setGW(responseData.data.status.gw);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 600000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])
  // },)

return {gw, fplstats, setUrl, newUrl,rankOrd,ordType,selections,stats_updated,show,handleShow,handleClose,xgInfo};
}


// <Container fluid className={styles.match_all + " shadow-lg"} style={{padding:"10px"}}>
//   <PlayerStatsXG fplStats ={fplstats} selections = {selections} setUrl={setUrl} newUrl = {newUrl} rankOrd={rankOrd} ordType={ordType} style={{padding:"20"}}/>
// </Container>

const Stats = () => {

  const {
    gw,
    fplstats,
    setUrl,
    newUrl,
    rankOrd,
    ordType,
    selections,
    stats_updated,
    show,
    handleShow,
    handleClose,
    xgInfo,
  } = useMatchesState();

  return (
    <Container fluid style={{ paddingLeft: 0,paddingRight: 5  }}>
      <Row>
        <Col xs={{span:12, order:2}} lg={{span:3, order:1}} style={{ marginLeft: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {1}/>
        </Col>
        <Col xs={{span:12, order:1}} lg={{span:6, order:2}} className={styles.App2}>
          <GwInfo gw={gw}/>
          <Container fluid className={styles.stats_sels}>
            <Row style={{ marginLeft: -5,marginRight: -5  }}>
              <Col style={{ paddingLeft: 5,paddingRight: 0  }}>Position?</Col>
              <Col style={{ paddingLeft: 5,paddingRight: 0  }}>Max Sel?</Col>
              <Col style={{ paddingLeft: 5,paddingRight: 0  }}>Max Cost?</Col>
              <Col style={{ paddingLeft: 5,paddingRight: 0  }}>Team?</Col>
            </Row>
          <SelForm selections = {selections} setUrl={setUrl} newUrl = {newUrl} rankOrd={rankOrd} ordType={ordType}/>
          </Container>
          <PlayerStatsXG className={styles.stats_box + " shadow-lg"} fplStats ={fplstats} selections = {selections} setUrl={setUrl} newUrl = {newUrl} rankOrd={rankOrd} ordType={ordType} handleShow = {handleShow}/>
          <Row className={styles.ft_text}>
            <Col>
            <p>Last Updated: {stats_updated}</p>
            <p> {"\u274c"} Out this game week<br/>{"\u2757"} Injury doubt<br/>{"\u2753"} Might not start</p>
            <p>C£:Cost, S%:Selected, Fm:Form<br/>
            Ps:Points, xG:Expteced Goals, xA:Expected Assists, xG+xA:Expcted Goals & Assists<br/>
            Opp xGA:Next Opponent Expected Goals Against, gRat:Goal Rating, aRat:Attacking Rating</p>
            <p> All 'Expected' data from <a href="https://fbref.com/en/comps/9/Premier-League-Stats">FBREF</a></p>
            </Col>
          </Row>
        </Col>
        <Col xs={{span:12, order:3}} lg={{span:3, order:3}} style={{ marginRight: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {2}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Stats;
